define("framework/globalUtils/defaultSubmodulePrototype", [], function() {
  function getDefaultSubmodulePrototype(parentModule, subModule) {
    var privateData = {};
    var targetContainer = "#cr__" + parentModule.channelId + "-" + subModule.name;

    return {
      initialize: default_initialize,
      uninitialize: default_uninitialize,
      onInitialized: $.noop,
      onUninitialized: $.noop,
      activate: default_activate,
      deactivate: default_deactivate,
      onActivated: $.noop,
      onDeactivated: $.noop
    };

    function default_initialize(parentModule, message) {
      var requireId = parentModule.channelId + "/" + this.channelId + "/" + this.channelId;

      this.requireId = requireId;
      this.$rootNode = $(targetContainer);

      var parentDiv = $("#" + parentModule.channelId + "Module");
      if (!this.$rootNode[0] && parentDiv[0]) {
        this.$rootNode = $("<div>").attr("id", targetContainer.substring(1));
        parentDiv.append(this.$rootNode);
      }

      if (!this.$rootNode[0])
        throw targetContainer +
          " div does not exist, which would be ok except the expected " +
          parentModule.channelId +
          "Module div does not exist either.  Either rectify, or override initialize";

      privateData.parentModule = parentModule;
      privateData.parentId = parentModule.channelId;
      privateData.initializeMessage = message;
      privateData.transmitRequest = cr.transport.transmitRequest.bind(cr.transport, privateData.parentId);
      privateData.successRequest = cr.transport.successRequest.bind(cr.transport, privateData.parentId);
      privateData.busConnection = cr.messageBus.getConnection(requireId);
      this._busConnection = privateData.busConnection;
      privateData.hashManager = new cr.hashUtil.hashManager();
      privateData.requireId = requireId;

      cr.router.loadSubmoduleHtml(privateData.parentModule.channelId, this.channelId, htmlLoadedCallback.bind(this));
    }

    function default_uninitialize() {
      privateData.busConnection.uninitialize();
      privateData.responsiveManager.dispose();
      if (typeof this.onUninitialize === "function") this.onUninitialize();

      if (privateData.content) {
        $(targetContainer)
          .hide()
          .append(privateData.content);
      }

      ko.cleanNode($(targetContainer)[0]);
      $(targetContainer).empty();

      for (var item in privateData) privateData[item] = null;

      privateData = null;
    }

    function default_activate(message, flag) {
      if (privateData.content) {
        $(targetContainer).append(privateData.content);

        privateData.content = null;
      }

      var hashManager = new cr.hashUtil.hashManager();
      var hashInfo = hashManager.parseHashTag(window.location.hash);
      var parameters = hashInfo.getParameters();

      this._busConnection.activateTrackedSubscriptions();

      //typically this will be a call to processHash() if needed;
      this.onActivated(hashInfo, parameters, message, flag);
    }

    function default_deactivate() {
      this._busConnection.deActivateTrackedSubscriptions();
      this.onDeactivated();

      privateData.content = $(targetContainer).children();
      privateData.content.detach();

      $("body")
        .removeClass("pml-open")
        .removeClass("pml-open-right");
    }

    function htmlLoadedCallback(htmlText) {
      $("body")
        .removeClass("pml-open")
        .removeClass("pml-open-right");

      $(targetContainer).html(htmlText);

      var hashManager = new cr.hashUtil.hashManager();
      var hashInfo = hashManager.parseHashTag(window.location.hash);
      var parameters = hashInfo.getParameters();

      // html is loaded so we can go ahead and fire up the responsive layout manager
      privateData.responsiveManager = new cr.responsiveManager();

      //htmlLoaded callback where handlers are wired up
      this.onInitialized(hashInfo, parameters, privateData);

      // send message the that module has loaded
      // we can then pick up this message, as an example, in the header
      // to ensure that we've closed mobile-friendly navigation and re-enabled the screenPane
      cr.messageBus.sendMessage("router", "module-loaded");
    }
  }

  return getDefaultSubmodulePrototype;
});
