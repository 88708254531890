define("framework/koMapper/plugins/singleItemEdit", [], function() {
  function getSingleEditMixin(data, config) {
    return {
      initialize: function() {
        var actuallyEditingItem = new config.vm();

        this.currentItem = ko.observable(new config.vm());
        this.editThisItem = function(item) {
          actuallyEditingItem = item;
          this.currentItem(config.vm.createFromResponse(ko.toJS(item)));

          if (config.validatingModal) {
            CRClearErrorsInModalAndShow(config.validatingModal.modal);
          }
        };
        this.newItem = function() {
          this.currentItem(new config.vm());
          if (config.validatingModal) {
            CRClearErrorsInModalAndShow(config.validatingModal.modal);
          }
        };
        this.saveCurrentItem = defaultSave;
        this.deleteItem = defaultDelete;
        this.saving = ko.observable(false);

        function defaultSave(successCallback, errorCallback, element) {
          // no errorCallback shift element over
          if (typeof errorCallback === "object") {
            element = errorCallback;
            errorCallback = undefined;
          }

          if (config.validatingModal) {
            if (!config.validatingModal.form.valid()) return;
          }

          this.saving(true);
          var request = this.savePacket ? this.savePacket() : ko.toJS(this.currentItem());
          cr.transport.successRequest(
            data.parentId || data.channelId,
            config.saveAction,
            request,
            element,
            function(resp) {
              typeof this.onSaveSuccess === "function" && this.onSaveSuccess.call(this, resp);

              if (this.currentItem()[config.id || "id"]()) {
                actuallyEditingItem.mapFromResponse(resp.fields);
              } else {
                var newItem = config.vm.createFromResponse(resp.fields);
                this[config.listProperty || "list"].unshift(newItem);
                this.editThisItem(newItem); //there's no law that says once you save an object the first time, you're done editing it
              }
              typeof successCallback === "function" && successCallback();
              this.saving(false);

              if (config.validatingModal) {
                config.validatingModal.modal.modal("hide");
              }
            }.bind(this),
            function(resp) {
              this.saving(false);
              typeof errorCallback === "function" && errorCallback(resp);
            }.bind(this)
          );
        }

        function defaultDelete(item, callback) {
          if (typeof config.deletePrompt === "string") {
            CRConfirm(
              config.deletePrompt,
              function() {
                executeDelete.call(this, item, callback);
              }.bind(this)
            );
          } else {
            executeDelete.call(this, item, callback);
          }
        }

        function executeDelete(item, callback) {
          cr.transport.successRequest(
            data.parentId || data.channelId,
            config.deleteAction,
            { id: item[config.id || "id"]() },
            function(resp) {
              this[config.listProperty || "list"].remove(item);
              typeof callback === "function" && callback();
            }.bind(this)
          );
        }
      }
    };
  }
  return getSingleEditMixin;
});
