define("framework/koMapper/plugins/searchVm", [], function() {
  return function(localConfig) {
    localConfig = localConfig || {};
    var toExclude = localConfig.exclude || [];

    return {
      initialize: function(allLabels, currentLabelId) {
        if (localConfig.continuousPaging) {
          this._continuousPaging = true; //hack - ohhhhhh yeah
        }
      },
      postInitialize: function() {
        var queryDirty = ko.observable(false),
          pageDirty = ko.observable(0),
          dirtyProperties = [];

        this.dirtyProperties = dirtyProperties;

        //searchVm.isDirty, the public property, is set automatically anytime a plain observable is modified.  This happens
        // since this very method, below, sets the local queryDirty observable any time one of these properties changes.
        // It is set manually at the relevant time when a search array is modified, for example a collection of included or excluded billing labels.
        // There are a number of reasons for this not being automated that neither space nor time permits me to get into.

        //Suffice it to say, if isDirty is manually being set to true, then some sort of query param has been modified.
        //This presumption is reflected in the write: portion below.  Sometimes it will be necessary to know if a searchVm
        //is dirty only because the page has been changed.  If so, queryDirty will be false even though isDirty is true.  A new load
        //needs to be fetched, but, any global totals can be kept since the user is only paging through the same result sets.
        this.isDirty = ko.computed({
          read: function() {
            return queryDirty() || pageDirty();
          },
          write: function(val) {
            if (!val) {
              dirtyProperties.length = 0;
              pageDirty(0);
              queryDirty(false);
            } else {
              //once again - if you manually set isDirty to true because the page observable has changed, then you are wrong; this happens
              //automatically.  Don't do that.
              queryDirty(true);
            }
          }
        });
        this.queryDirty = queryDirty;

        for (var prop in this) {
          if (this.hasOwnProperty(prop) && ko.isObservable(this[prop]) && !ko.isComputed(this[prop]) && !$.isArray(this[prop]())) {
            if (prop == "page" && localConfig.continuousPaging) continue;
            if (prop == "queryDirty") continue;
            if (toExclude.indexOf(prop) > -1) continue;

            if (prop === "page" || prop === "pageSize") {
              //changing the page does not make queryDirty true
              this[prop].subscribe(function(val) {
                pageDirty(pageDirty() + 1);
              });
            } else {
              (function(prop) {
                this[prop].subscribe(function(val) {
                  if (dirtyProperties.indexOf(prop) === -1) {
                    dirtyProperties.push(prop);
                  }
                  queryDirty(true);
                });
              }.call(this, prop));
            }
          }
        }
      }
    };
  };
});
