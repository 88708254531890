define([], function() {
  var requireId = "vm-plugin-selectableList";

  return function(localConfig) {
    var alias = localConfig.alias;

    return {
      initialize: function() {
        if (alias) this[alias] = this.items;

        this.loaded = ko.observable(false);
        this.loading = ko.observable(false);
        this.fresh = ko.observable(true);
        this.hasMore = ko.observable(false);

        // running total of how many items loaded thus far and if we anticipate that there are more
        this.displayTotal = ko.computed(function() {
          return this.items().length + (this.hasMore() ? "+" : "");
        }, this);

        this.finishLoad = function(items, append, pageSize) {
          // Allow for a call saying "we're done, but didn't get anything"
          if (items) {
            if (append) {
              this.items.push.apply(
                this.items,
                items.map(function(item) {
                  return localConfig.vm.createFromResponse(item);
                })
              );
            } else {
              this.mapFromResponse({ items: items });
            }

            // if the returned list has < items than page size we definitely have no more results. Otherwise we likely do; false positives are possible
            if (typeof pageSize !== "undefined") {
              this.hasMore(items.length === pageSize);
            }
          }
          this.loading(false);
          this.loaded(true);
          this.fresh(false);
        };

        this.getItemById = function(id) {
          return Linq.From(this.items()).SingleOrDefault(null, function(item) {
            return item.id() == id;
          });
        };

        this.selectToggleItem = function(item, e) {
          item.selected(!item.selected());
        };
        this.selectItem = function(item, e) {
          item.selected(true);
        };
        this.unSelectItem = function(item, e) {
          item.selected(false);
        };

        this.selectedItems = ko.computed(function() {
          return this.items().filter(function(i) {
            return i.selected();
          });
        }, this);
        this.selectedCount = ko.computed(function() {
          return this.selectedItems().length;
        }, this);

        this.toggleAllSelected = function() {
          this.allSelected(!this.allSelected());
        }.bind(this);

        this.allSelected = ko.computed({
          read: function() {
            return this.items().length && this.selectedCount() == this.items().length;
          },
          write: function(val) {
            return this.items().forEach(function(i) {
              i.selected(val);
            });
          },
          owner: this
        });
      },
      define: function(config, pubs, F) {
        config.mappedArrays.push({ name: "items", viewModelType: localConfig.vm });

        if (alias) {
          config.mappedArrays.push({ name: alias, viewModelType: localConfig.vm });
        }
      }
    };
  };
});
