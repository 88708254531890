define(
  "framework/koMapper/mapper",
  [
    "framework/koMapper/plugins/singleItemEdit",
    "framework/koMapper/plugins/selectableList",
    "framework/koMapper/plugins/selectableItem",
    "framework/koMapper/plugins/nestedLabels",
    "framework/koMapper/plugins/searchVm",
    "framework/globalUtils/labelUtilities",
    "framework/koMapper/plugins/advancedSearch",
    "framework/koMapper/plugins/sideMenu",
    "framework/koMapper/plugins/basicSelectableList",
    "framework/koMapper/plugins/basicSelectableItem"
  ],
  function(
    singleItemEdit,
    selectableList,
    selectableItem,
    nestedLabels,
    searchVm,
    labelUtilities,
    advSearch,
    sideMenu,
    basicSelectableList,
    basicSelectableItem
  ) {
    var viewModelFactory = {};

    viewModelFactory.plugins = {
      addLabelsCollectionGeneric: labelUtilities.addGenericLabelsCollection,
      singleItemEdit: singleItemEdit,
      selectableList: selectableList,
      selectableItem: selectableItem,
      nestedLabels: nestedLabels,
      singleLabelsCollectionSelect2: labelUtilities.singleLabelsCollectionSelect2Plugin,
      searchVm: searchVm,
      advancedSearch: advSearch,
      sideMenu: sideMenu,
      basicSelectableList: basicSelectableList,
      basicSelectableItem: basicSelectableItem
    };

    viewModelFactory.RECURSIVE_RELATIONSHIP = "***";
    viewModelFactory.create = create;
    function getAllObservablesIncludingInherited(vm) {
      //TODO: implement memoization / caching???
      var result = vm.config.basicObservables;
      if (!(vm.config && vm.config.inheritFrom && vm.config.inheritFrom.config)) return result;
      return result.concat(getAllObservablesIncludingInherited(vm.config.inheritFrom));
    }

    function getAllMappedArraysIncludingInherited(vm) {
      //TODO: implement memoization / caching???
      var result = vm.config.mappedArrays;
      if (!(vm.config && vm.config.inheritFrom && vm.config.inheritFrom.config)) return result;
      return result.concat(getAllMappedArraysIncludingInherited(vm.config.inheritFrom));
    }

    function getAllPropertiesIncludingInherited(vm) {
      //TODO: implement memoization / caching???
      var result = vm.config.basicProperties;
      if (!(vm.config && vm.config.inheritFrom && vm.config.inheritFrom.config)) return result;
      return result.concat(getAllPropertiesIncludingInherited(vm.config.inheritFrom));
    }

    function getAllObservableArraysIncludingInherited(vm) {
      //TODO: implement memoization / caching???
      var result = vm.config.basicObservableArrays;
      if (!(vm.config && vm.config.inheritFrom && vm.config.inheritFrom.config)) return result;
      return result.concat(getAllObservableArraysIncludingInherited(vm.config.inheritFrom));
    }

    viewModelFactory.viewModelPrototype = (function() {
      return {
        projectToJS: function() {
          var result = {};
          for (var i = 0; i < arguments.length; i++) {
            result[arguments[i]] = this[arguments[i]];
          }
          return ko.toJS(result);
        },
        configureValidation: function(nameOverride) {
          var validationObject = {};
          for (var prop in this) {
            if (this.hasOwnProperty(prop) && isValidated(this[prop])) {
              validationObject[prop] = this[prop];
            }
          }
          var validatingObject = ko.validatedObservable(validationObject);
          this[nameOverride || "validation"] = validatingObject;

          this.checkValidation = function() {
            if (!validatingObject().isValid()) {
              validatingObject().errors.showAllMessages(true);
              return false;
            }
            return true;
          };

          this.resetValidation = function() {
            for (var prop in this[nameOverride || "validation"]()) {
              if (this.hasOwnProperty(prop)) {
                this[prop].isModified(false);
              }
            }
          };
        },
        registerMappedArray: function(name, viewModelType) {
          if (!this.constructor.config.mappedArrays) {
            this.constructor.config.mappedArrays = [];
          }
          this.constructor.config.mappedArrays.push({ name: name, viewModelType: viewModelType });
          this[name] = ko.observableArray([]);
        },
        registerMappedObject: function(name, viewModelType) {
          if (!this.constructor.config.mappedObjects) {
            this.constructor.config.mappedObjects = [];
          }
          this.constructor.config.mappedObjects.push({ name: name, viewModelType: viewModelType });
          this[name] = ko.observable(new viewModelType());
        }
      };

      function isValidated(prop) {
        if (prop == null) return false;

        return (
          typeof prop.error === "function" &&
          typeof prop.isValid === "function" &&
          typeof prop.isValidating === "function" &&
          typeof prop.clearError === "function"
        );
      }
    })();

    function createMappedObject(parent, servObject, viewModelType, create) {
      var obj;
      if (create) {
        if (viewModelType) {
          obj = viewModelType.createFromResponse(servObject, parent, create);
        } else {
          obj = create.call(parent);
          obj.mapFromResponse(servObject);
        }
      } else {
        obj = viewModelType.createFromResponse(servObject);
      }
      return obj;
    }

    function create() {
      var name = "",
        config;

      //TODO - validate this data
      if (arguments.length === 1) {
        config = arguments[0];
      } else if (arguments.length === 2) {
        name = arguments[0];
        config = arguments[1];
      }

      function F() {
        var inheritFrom = F.config.inheritFrom,
          inheritsFromMappedVm = inheritFrom && inheritFrom.config;

        if (inheritsFromMappedVm) {
          //don't call initialize directly - invoke the function itself, so ITS parent will be called, if needed
          inheritFrom.apply(this, arguments);
        }

        for (var i = 0; i < config.plugins.length; i++) {
          typeof config.plugins[i].preInitialize === "function" && config.plugins[i].preInitialize.call(this);
        }

        initialize.apply(this, arguments);
      }
      F.prototype = Object.create((config.inheritFrom && config.inheritFrom.prototype) || viewModelFactory.viewModelPrototype);
      if (config.prototype) {
        Object.keys(config.prototype).forEach(function(prop) {
          Object.defineProperty(F.prototype, prop, Object.getOwnPropertyDescriptor(config.prototype, prop));
        });
      }
      F.prototype.constructor = F;
      F.config = config;

      if (config.initialize && typeof config.initialize !== "function") {
        throw "Invalid use: initialize must be a function";
      }
      if (config.basicObservables && !$.isArray(config.basicObservables)) {
        throw "Invalid use: basicObservables must be an array";
      }
      if (config.basicObservableArrays && !$.isArray(config.basicObservableArrays)) {
        throw "Invalid use: basicObservableArrays must be an array";
      }

      if (!config.basicObservables) config.basicObservables = [];
      if (!config.basicProperties) config.basicProperties = [];
      if (!config.basicObservableArrays) config.basicObservableArrays = [];

      if (!config.mappedArrays) {
        config.mappedArrays = [];
      }
      if (!config.plugins) {
        config.plugins = [];
      }
      if (config.pagingInfo) {
        if (config.pagingInfo.type === "persistent") {
          config.basicObservables.push("hasNext");
        } else {
          config.basicObservables.push("totalItems");
        }
      }

      config.__onMapHandlers = [];
      config.__executeOnMap = function(self) {
        this.__onMapHandlers.forEach(sub => sub.call(self));
      };

      config.onMap = function(fn) {
        this.__onMapHandlers.push(fn);
      };

      for (var i = 0; i < config.plugins.length; i++) {
        typeof config.plugins[i].define === "function" && config.plugins[i].define.call(null, config, F.prototype, F);
        if (typeof config.plugins[i].onMap === "function") {
          config.__onMapHandlers.push(config.plugins[i].onMap);
        }
      }

      function initialize() {
        if (config.basicObservables) {
          for (var i = 0, max = config.basicObservables.length; i < max; i++) {
            this[config.basicObservables[i]] = ko.observable("");
          }
        }
        if (config.basicProperties) {
          for (var i = 0, max = config.basicProperties.length; i < max; i++) {
            this[config.basicProperties[i]] = "";
          }
        }
        if (config.basicObservableArrays) {
          for (i = 0, max = config.basicObservableArrays.length; i < max; i++) {
            this[config.basicObservableArrays[i]] = ko.observableArray([]);
          }
        }
        if (config.mappedArrays) {
          for (i = 0, max = config.mappedArrays.length; i < max; i++) {
            this[config.mappedArrays[i].name] = ko.observableArray([]);
          }
        }
        if (config.mappedObjects) {
          for (i = 0, max = config.mappedObjects.length; i < max; i++) {
            var packet = config.mappedObjects[i];
            var obj = packet.create ? packet.create.call(this, packet.viewModelType) : new packet.viewModelType();
            this[config.mappedObjects[i].name] = ko.observable(obj);
          }
        }

        if (config.pagingInfo) {
          const pagingType = config.pagingInfo.type || "static";

          var collectionName = config.pagingInfo.collection;
          this.pageSize = ko.observable(config.pagingInfo.pageSize || 10);
          this.page = ko.observable(1);

          if (pagingType === "persistent") {
            F.prototype.canPageUp = function canPageUp() {
              return this.hasNext();
            };

            F.prototype.canPageDown = function canPageDown() {
              return this.page() > 1;
            };
          } else {
            // In more and more cases we'll have to forgo the totalitems being returned for performance reasons
            // and instead use a gmail-like approach where we never fully will know the actual total
            // Though in the future we may aggregate a running total.

            this.totalPages = ko.computed(() => (pagingType == "static" ? Math.ceil(this.totalItems() / this.pageSize()) : -1));
            this.canPageFirst = this.canPagePrevious = this.canPageDown = ko.computed(() => this.page() > 1);
            this.canPageNext = this.canPageUp = ko.computed(
              () => (pagingType == "static" ? this.page() < this.totalPages() : this[collectionName]().length === this.pageSize())
            );
            this.canPageLast = ko.computed(() => (pagingType == "static" ? this.page() < this.totalPages() : false));
            this.supportsPageLast = ko.computed(() => pagingType == "static");
          }

          this.isMultiPage = ko.computed(() => this.canPageUp() || this.canPageDown());
          this.isSinglePage = ko.computed(() => !this.isMultiPage());
        }

        for (i = 0; i < config.plugins.length; i++) {
          typeof config.plugins[i].initialize === "function" && config.plugins[i].initialize.apply(this, arguments);
        }

        if (config.initialize) {
          config.initialize.apply(this, arguments);
        }

        for (i = 0; i < config.plugins.length; i++) {
          typeof config.plugins[i].postInitialize === "function" && config.plugins[i].postInitialize.call(this);
        }
      }

      F.prototype.resetViewModel = resetViewModel;
      function resetViewModel() {
        if (config.pagingInfo) {
          this.page(1);
        }

        var observablesToMap = getAllObservablesIncludingInherited(F);
        for (var i = 0, max = observablesToMap.length; i < max; i++) {
          this[observablesToMap[i]]("");
        }

        var observableArraysToMap = getAllObservableArraysIncludingInherited(F);
        for (var i = 0, max = observableArraysToMap.length; i < max; i++) {
          this[observableArraysToMap[i]]([]);
        }

        var propertiesToMap = getAllPropertiesIncludingInherited(F);
        for (var i = 0, max = propertiesToMap.length; i < max; i++) {
          this[propertiesToMap[i]] = "";
        }

        if (config.mappedObjects) {
          for (i = 0, max = config.mappedObjects.length; i < max; i++) {
            if (this[config.mappedObjects[i].name] && this[config.mappedObjects[i].name]().resetViewModel) {
              this[config.mappedObjects[i].name]().resetViewModel();
            }
          }
        }
        var arraysToMap = getAllMappedArraysIncludingInherited(F);
        for (i = 0, max = arraysToMap.length; i < max; i++) {
          if (this[arraysToMap[i].name]) {
            this[arraysToMap[i].name]([]);
          }
        }
      }

      F.prototype.mapFromResponse = mapFromResponse;
      function mapFromResponse(response) {
        var observablesToMap = getAllObservablesIncludingInherited(F);
        for (var i = 0, max = observablesToMap.length; i < max; i++) {
          var prop = observablesToMap[i];
          var respValue = getPropertyByNameCaseInsensitive(response, prop);
          if (respValue != null) {
            this[prop](respValue);
          }
        }

        var observableArraysToMap = getAllObservableArraysIncludingInherited(F);
        for (var i = 0, max = observableArraysToMap.length; i < max; i++) {
          var prop = observableArraysToMap[i];
          var respValue = getPropertyByNameCaseInsensitive(response, prop);
          if (respValue != null) {
            this[prop](respValue);
          }
        }

        var propertiesToMap = getAllPropertiesIncludingInherited(F);
        for (var i = 0, max = propertiesToMap.length; i < max; i++) {
          var prop = propertiesToMap[i];
          var respValue = getPropertyByNameCaseInsensitive(response, prop);
          if (respValue != null) {
            this[prop] = respValue;
          }
        }

        if (config.mappedObjects) {
          for (i = 0, max = config.mappedObjects.length; i < max; i++) {
            var packet = config.mappedObjects[i];
            var servObject = getPropertyByNameCaseInsensitive(response, packet.name);

            if (servObject != null) {
              this[packet.name](createMappedObject(this, servObject, packet.viewModelType, packet.create));
            }
          }
        }

        var arraysToMap = getAllMappedArraysIncludingInherited(F);
        for (i = 0, max = arraysToMap.length; i < max; i++) {
          var packet = arraysToMap[i];
          var servArray = getPropertyByNameCaseInsensitive(response, packet.name);

          if (servArray != null && $.isArray(servArray)) {
            this[packet.name]([]);
            var newValues = [];
            $.each(
              servArray,
              function(i, elem) {
                var viewModelType = packet.viewModelType === viewModelFactory.RECURSIVE_RELATIONSHIP ? F : packet.viewModelType;
                newValues.push(createMappedObject(this, elem, viewModelType, packet.create));
              }.bind(this)
            );
            this[packet.name](newValues);
          }
        }

        config.__executeOnMap(this);

        return this;

        function getPropertyByNameCaseInsensitive(obj, propertyName) {
          var Reg = new RegExp("\\b" + propertyName + "\\b", "i");
          for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) continue;
            if (Reg.test(prop)) return obj[prop];
          }
        }
      }

      F.createFromResponse = createFromResponse;
      function createFromResponse(resp, parent, creator) {
        var result = creator ? creator.call(parent, F) : new F();
        result.mapFromResponse(resp);
        return result;
      }

      return F;
    }

    var hierarchicalLabelVm = viewModelFactory.create({
      basicObservables: ["id", "name", "color", "backgroundColor", "total", "parentLabelId", "isPublic"],
      mappedArrays: [{ name: "children", viewModelType: viewModelFactory.RECURSIVE_RELATIONSHIP }],
      initialize: function() {
        this.truncatedName = ko.computed(function() {
          return this.name().truncate(20);
        }, this);

        this.collapsed = ko.observable(true);

        this.showChildren = ko.observable(false);
        this.hasChildren = ko.computed(function() {
          return this.children().length;
        }, this);

        this.childrenSorted = ko.computed(function() {
          return Linq.From(this.children())
            .OrderBy(function(l) {
              return l.name().toLowerCase();
            })
            .ToArray();
        }, this);
      }
    });

    hierarchicalLabelVm.prototype.collapse = function() {
      this.collapsed(true);
    };

    hierarchicalLabelVm.prototype.expand = function() {
      this.collapsed(false);
    };

    function formatLabelResponse(label) {
      label.BackgroundColor = label.Color.length > 0 ? label.Color.split("|")[0] : "cccccc";
      label.Color = label.Color.length > 0 ? label.Color.split("|")[1] : "000000";
      return label;
    }

    function flattenLabelCollection(labels, result) {
      result = result || [];

      $.each(
        Linq.From(labels)
          .OrderBy(function(l) {
            return l.name().toLowerCase();
          })
          .ToArray(),
        function(i, lab) {
          result.push(lab);
          flattenLabelCollection(lab.children(), result);
        }
      );

      return result;
    }

    function shapeHierarchicalLabelCollection(objResponse) {
      if (objResponse.labels.length > 0) {
        var queryableLabels = Linq.From(objResponse.labels);

        $.each(objResponse.labels, function(i, label) {
          hierarchicalLabelVm.formatLabelResponse(label);
          label.Children = queryableLabels
            .Where(function(l) {
              return l.parentLabelId == label.Id;
            })
            .ToArray();
        });

        return queryableLabels
          .Where(function(label) {
            return !+label.parentLabelId;
          })
          .ToArray();
      }
      return [];
    }

    hierarchicalLabelVm.formatLabelResponse = formatLabelResponse;
    hierarchicalLabelVm.flattenLabelCollection = flattenLabelCollection;
    hierarchicalLabelVm.shapeHierarchicalLabelCollection = shapeHierarchicalLabelCollection;

    var individualLabelVm = viewModelFactory.create({
      basicObservables: ["isPrivate", "id", "parentId", "name", "backgroundColor", "color", "hasLabel"],
      initialize: function() {
        this.text = this.name;
      }
    });

    function shapeIndividualLabelCollection(labels) {
      var prelim = labels.split(",");

      //label names can have a comma in them.  If they do, the split will have false positives.  Since the UDF replaces , with "," (adds quotes)
      //these false positives will always start with a double quote, and will always be preceded by an entry ending with a ".  This circumstance
      //is otherwise impossible, again because of how the UDF works.
      for (var i = prelim.length - 1; i >= 0; i--) {
        var current = prelim[i],
          prev = prelim[i - 1];

        if (current[0] === '"' && prev && prev[prev.length - 1] === '"') {
          prelim = prelim
            .slice(0, i - 1)
            .concat([prev.substr(0, prev.length - 1), ",", current.substr(1)].join(""))
            .concat(prelim.slice(i + 1));
        }
      }

      return $.map(prelim, function(label) {
        var vals = label.split("|"),
          extras = vals.length - 5;

        //label names may have pipes - if so, we'll get more segments than we should, and they'll always be at the beginning because of how the UDF
        //works.  This code combines them
        if (extras > 0) {
          //don't think it could be negative, but better safe
          vals = [vals.slice(0, extras + 1).join("|")].concat(vals.slice(extras + 1));
        }
        return label
          ? {
              name: $.trim(vals[0]),
              id: $.trim(vals[1]),
              backgroundColor: $.trim(vals[2]) || "dddddd",
              color: vals[3] || "000000",
              isPublic: !!+vals[4]
            }
          : null;
      });
    }
    individualLabelVm.shapeIndividualLabelCollection = shapeIndividualLabelCollection;

    var multiSeachInstanceNonExcludableVm = viewModelFactory.create({
      basicObservables: ["id", "name", "error"],
      initialize: function(id, name) {
        if (typeof id !== "undefined") {
          this.id(id);
        }
        if (typeof name !== "undefined") {
          this.name(name);
        }
      }
    });
    multiSeachInstanceNonExcludableVm.prototype.nonExcludable = true;
    multiSeachInstanceNonExcludableVm.prototype.isIncluded = ko.observable(true);

    var multiSeachInstanceVm = viewModelFactory.create({
      basicObservables: ["id", "name", "isIncluded", "error"],
      initialize: function(id, included, name) {
        this.isIncluded(true);

        if (typeof id !== "undefined") {
          this.id(id);
        }
        if (typeof included !== "undefined") {
          this.isIncluded(included);
        }
        if (typeof name !== "undefined") {
          this.name(name);
        }
        this.noSelection = ko.computed(() => {
          return typeof this.isIncluded() !== 'boolean';
        });
      }
    });

    multiSeachInstanceVm.prototype.include = function() {
      this.isIncluded(true);
    };

    multiSeachInstanceVm.prototype.exclude = function() {
      this.isIncluded(false);
    };

    viewModelFactory.commonViewModels = {
      individualLabelVm: individualLabelVm,
      hierarchicalLabelVm: hierarchicalLabelVm,
      multiSeachInstanceVm: multiSeachInstanceVm,
      multiSeachInstanceNonExcludableVm: multiSeachInstanceNonExcludableVm
    };

    ko.mappedArray = function createMappedArray(vmType) {
      var result = ko.observableArray([]);
      result.set = mapAndSetKoArray.bind(result, vmType);
      return result;
    };
    function mapAndSetKoArray(vmType, arr) {
      this(
        arr.map(function(val) {
          return vmType.createFromResponse(val);
        })
      );
    }

    ko.observableArray.fn.mapAndUpdate = function(item, map) {
      item.mapFromResponse(map);
      var index = this.indexOf(item);

      if (index < 0) return;

      //this.splice(index, 1, item);
      this.splice(index, 1);
      this.splice(index, 0, item);
    };

    return viewModelFactory;
  }
);
