define("framework/cr", [
  "framework/globalUtils/settings",
  "framework/globalUtils/domUtil",
  "app/util/router",
  "framework/globalUtils/user",
  "framework/globalUtils/autocompleter",
  "framework/globalUtils/util",
  "framework/globalUtils/hashUtil",
  "framework/globalUtils/transport",
  "framework/globalUtils/responsive",
  "framework/globalUtils/defaultSubmodulePrototype",
  "framework/moduleAddons/modAddons",
  "framework/moduleAddons/subModAddons",
  "app/util/messageBus",
  "framework/globalUtils/koNodePreprocessor",
  "framework/koMapper/mapper",
  "app/util/extensions"
], function(
  settingsUtil,
  domUtil,
  { default: router },
  user,
  ac,
  util,
  hashUtil,
  transportUtil,
  responsiveManager,
  subModPrototype,
  modAddons,
  subModAddons,
  messageBusPacket,
  koPreprocessor,
  vmFactory
) {
  var currentUser = null;

  return {
    locale: "en_us",
    clientRoot: "",
    channelId: "framework",
    moduleAddons: modAddons,
    subModuleAddons: subModAddons,
    globalHashManager: new hashUtil.hashManager(),
    setUser: function(user) {
      currentUser = user;
    },
    getUser: function() {
      return currentUser;
    },
    checkBoxSyncPacket: Symbol("cr_checkbox_sync"),

    // Usage: `throw createException("Your message here")`
    createException: function(msg) {
      return typeof Error === "undefined" ? msg : new Error(msg);
    },

    userViewState: settingsUtil,
    domUtil: domUtil,
    router: router,
    createAutoCompleter: ac,
    util: util,
    CRUser: user,
    hashUtil: hashUtil,
    transportUtil: transportUtil,
    responsiveManager: responsiveManager,
    defaultSubmodulePrototype: subModPrototype,
    messageBus: messageBusPacket.default,
    createMessageBus: function() {
      return new messageBusPacket.MessageBus();
    },
    viewModelFactory: vmFactory,
    widgets: koPreprocessor,
    configureValidation: function(obj, name) {
      vmFactory.viewModelPrototype.configureValidation.call(obj, name);
    }
  };
});
