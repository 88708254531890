function registerAdvancedSearchOptions(options) {
  let reversions = {},
    existingOptions = cr.viewModelFactory.plugins.advancedSearch.advancedSearchResultsMap;

  Object.keys(options).forEach(key => {
    if (existingOptions.hasOwnProperty(key)) {
      reversions[key] = existingOptions[key];
      delete existingOptions[key];
    }
  });

  cr.viewModelFactory.plugins.advancedSearch.registerAdvancedSearchOptions(options);
  this.__registeredAdvSearchOptions = options;
  this.__registeredAdvSearchOptionsReversions = reversions;
}

function executeAddon(moduleOrSubmodule) {
  moduleOrSubmodule.prototype.registerAdvancedSearchOptions = registerAdvancedSearchOptions;
}

module.exports = executeAddon;
