define("framework/koMapper/plugins/nestedLabels", ["framework/globalUtils/labelUtilities"], function(labelUtilities) {
  var labelModal = labelUtilities.CRHierarchicalLabelModal;

  function loadLabels(channel, action) {
    var def = new $.Deferred();
    cr.transport.transmitRequest(channel, action, {}, function(resp) {
      var labels = cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.shapeHierarchicalLabelCollection(resp),
        labelVms = labels.map(function(l) {
          return cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.createFromResponse(l);
        });

      def.resolve(ko.observableArray(labelVms));
    });
    return def.promise();
  }

  function flattenLabels(labels) {
    var result = ko.computed(function() {
      return cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.flattenLabelCollection(labels());
    });
    result.getHierarchyLevel = createGetHierarchyLevelFunction(result);
    result.getHierarchyName = createGetHierarchyNameFunction(result);
    return result;
  }

  function flattenAndSortLabels(labels) {
    var result = ko.computed(function() {
      var res = cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.flattenLabelCollection(labels());
      return Linq.From(res)
        .OrderBy(function(l) {
          return l.name().toLowerCase();
        })
        .ToArray();
    });
    result.getHierarchyLevel = createGetHierarchyLevelFunction(result);
    result.getHierarchyName = createGetHierarchyNameFunction(result);
    return result;
  }

  function createGetHierarchyLevelFunction(labels) {
    return function getHierarchyLevel(label) {
      if (typeof label.hierarchyLevel === "undefined") {
        label.hierarchyLevel = ko.observable("");
      }
      if (!label.parentLabelId()) {
        label.hierarchyLevel(0);
      }

      if (typeof label.hierarchyLevel() === "number") {
        return label.hierarchyLevel();
      }

      var parentLabel = Linq.From(labels()).SingleOrDefault(null, function(lbl) {
        return lbl.id() == label.parentLabelId();
      });
      label.hierarchyLevel(getHierarchyLevel(parentLabel) + 1);
      return label.hierarchyLevel();
    };
  }

  function createGetHierarchyNameFunction(labels) {
    return function getHierarchyName(label) {
      if (!label.parentLabelId) {
        return typeof label.name === "function" ? label.name() : label.name;
      }
      var result = [];
      for (var i = 0; i < labels.getHierarchyLevel(label); i++) {
        result.push(" > ");
      }
      result.push(label.name());
      return result.join("");
    };
  }

  function nestedLabelsPlugin(options) {
    return {
      initialize: function() {
        if (typeof options.busConnection === "function") {
          options.busConnection = options.busConnection();
        }
        this.nestedLabelsBusConnection = options.busConnection;

        var permissionId = options.permissionId;

        this.labels = ko.observableArray([]);
        this.labelsPrivateSorted = ko.computed(function() {
          return Linq.From(this.labels())
            .Where(function(l) {
              return !l.isPublic();
            })
            .OrderBy(function(l) {
              return l.name().toLowerCase();
            })
            .ToArray();
        }, this);
        this.labelsPublicSorted = ko.computed(function() {
          return Linq.From(this.labels())
            .Where(function(l) {
              return l.isPublic();
            })
            .OrderBy(function(l) {
              return l.name().toLowerCase();
            })
            .ToArray();
        }, this);

        this.labelsLoaded = ko.observable(false);
        this.labelsLoading = ko.observable(false);
        this.allLabels = flattenLabels(this.labels);
        this.allLabelsSorted = flattenAndSortLabels(this.labels);
        this.allPrivateLabelsSorted = flattenLabels(this.labelsPrivateSorted);
        this.allPublicLabelsSorted = flattenLabels(this.labelsPublicSorted);

        this.orgLabels = ko.observableArray([]);
        this.allOrgLabels = ko.computed(function() {
          return cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.flattenLabelCollection(this.orgLabels());
        }, this);

        this.publicLabelsHeader = "Organization labels";
        this.privateLabelsHeader = cr.getUser().isCurrentlyTheOrganization() ? "Organization labels" : "Private labels";
        this.canEditOrgLabels = !cr.getUser().isCurrentlyTheOrganization() && permissionId && cr.getUser().hasPermission(permissionId);

        (options.dependentViewModels || []).forEach(
          function(vm) {
            if (!vm.prototype.allLabels) {
              vm.prototype.allLabels = this.allLabels;
              vm.prototype.allLabelsSorted = this.allLabelsSorted;
            }
          }.bind(this)
        );

        this.bulkLabelsModalOpen = ko.observable(null);

        this.bulkLabelsCancel = function() {
          this.selectedMode("");
          this.labelsAddingCollection([]);
          this.labelsRemovingCollection([]);
        }.bind(this);

        this.labelsAddingCollection = ko.observableArray([]);
        this.labelsRemovingCollection = ko.observableArray([]);

        this.showBulkLabelModal = function(noCheck) {
          if (!this.selectedCount() && !noCheck) {
            cr.domUtil.displayMessage("Nothing is selected", { isError: true });
            return;
          }
          this.labelsAddingCollection([]);
          this.labelsRemovingCollection([]);
          this.bulkLabelsModalOpen(true);
        }.bind(this);

        this.applyingBulkLabels = ko.startStopObservable();
        this.bulkLabelsApply = function(vm, evt) {
          var btn = evt && evt.target;
          if (!this.selectedCount()) return;
          if (!this.labelsAddingCollection().length && !this.labelsRemovingCollection().length) {
            cr.domUtil.displayMessage("No labels are selected", { isError: true });
            return;
          }

          // Get selected on page OR get all items not displayed on page.
          var getAll = this.allSelected && this.allSelected() && (this.totalSelected && this.totalSelected());
          var ids = getAll
            ? this.allIds()
            : this.selectedItems()
                .map(function(item) {
                  return ko.unwrap(item.id);
                })
                .join(",");

          this.applyingBulkLabels.start();
          var req = {
            ids: ids,
            add: this.labelsAddingCollection().join(","),
            remove: this.labelsRemovingCollection().join(",")
          };
          cr.transport.transmitRequest(
            options.transportId,
            options.bulkApplyAction,
            req,
            btn,
            function() {
              try {
                if (options.bulkUpdate) {
                  this.reconcileListItemsLabelsAfterMassUpdate(req);
                }
                options.busConnection.sendMessage("bulk-labels-set", req);
              } finally {
                this.applyingBulkLabels.stop();
                this.bulkLabelsModalOpen(false);
              }
            }.bind(this)
          );
        }.bind(this);

        var bus = cr.createMessageBus();

        var labelModalPrivateMessageBus = bus.getConnection("labelsModal");
        this.labelModal = new labelModal(this.allPrivateLabelsSorted, labelModalPrivateMessageBus, false);
        this.orgLabelModal = new labelModal(this.allPublicLabelsSorted, labelModalPrivateMessageBus, true);
        this.labelModalData = ko.observable(null);

        this.openPrivateLabelModal = function() {
          this.labelModalData(this.labelModal);
          this.labelModalData().refreshNonBoundItems();
        };
        this.openOrgLabelModal = function() {
          this.labelModalData(this.orgLabelModal);
          this.labelModalData().refreshNonBoundItems();
        };

        this.labelModalData.subscribe(
          function(oldModalPacket) {
            if (oldModalPacket) {
              oldModalPacket.updateColors();
            }
          },
          null,
          "beforeChange"
        );

        labelModalPrivateMessageBus.subscribe(
          "labelsModal",
          "saveLabel",
          function(token) {
            this.saveLabel(
              token.label,
              token.savePacket,
              function() {
                labelModalPrivateMessageBus.sendMessage("labelSaved", {});
              },
              token.button
            );
          }.bind(this)
        );

        labelModalPrivateMessageBus.subscribe(
          "labelsModal",
          "deleteLabel",
          function(token) {
            this.deleteLabel(token.label, token.button, function() {
              labelModalPrivateMessageBus.sendMessage("labelDeleted", {});
            });
          }.bind(this)
        );

        this.reconcileListItemsLabelsAfterMassUpdate = function(updatePacket) {
          if (typeof updatePacket.ids === "number") {
            updatePacket.ids = "" + updatePacket.ids;
          }
          var ids = updatePacket.ids.split(",").map(function(id) {
            return +id;
          });
          for (var i = 0, len = this.items().length; i < len; i++) {
            if (ids.indexOf(+ko.unwrap(this.items()[i].id)) > -1) {
              this.reconcileItemsLabelsAfterMassUpdate(this.items()[i], updatePacket);
            }
          }
        };
        this.reconcileItemsLabelsAfterMassUpdate = function(item, updatePacket) {
          if (!item) return;
          if (updatePacket.ids.split(",").indexOf("" + ko.unwrap(item.id)) < 0) return;

          updatePacket.add.split(",").forEach(
            function(id) {
              var labelToAdd;
              if (!item.getLabelById(id) && (labelToAdd = this.getLabelById(id))) {
                item.labels.push(cr.viewModelFactory.commonViewModels.individualLabelVm.createFromResponse(ko.toJS(labelToAdd)));
              }
            }.bind(this)
          );

          updatePacket.remove.split(",").forEach(
            function(id) {
              var labelToRemove;
              if ((labelToRemove = item.getLabelById(id))) {
                item.labels.remove(labelToRemove);
              }
            }.bind(this)
          );
        };

        this.privateLabelsHeader = cr.getUser().isCurrentlyTheOrganization() ? "Organization labels" : "Private labels";
      },
      define: function(config, pubs, F) {
        pubs.loadLabels = function(callback) {
          this.labelsLoading(true);
          var result = new $.Deferred();
          $.when(loadLabels(options.transportId, options.loadAction)).then(
            function(labels) {
              this.labels(labels());
              this.labelsLoaded(true);
              this.labelsLoading(false);

              typeof callback === "function" && callback();
              result.resolve(this.labels);
            }.bind(this)
          );
          return result.promise();
        };

        pubs.addLabel = function(label) {
          this.labels.push(cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.createFromResponse(label));
        };

        pubs.addOrgLabel = function(label) {
          this.orgLabels.push(cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.createFromResponse(label));
        };

        pubs.doesThisLabelExist = function(id) {
          return Linq.From(this.allLabels()).Any(function(l) {
            return l.id() == id;
          });
        };

        pubs.getLabelById = function(id) {
          return Linq.From(this.allLabels()).SingleOrDefault(null, function(label) {
            return label.id() == id;
          });
        };

        pubs.saveLabel = function(label, savePacket, callback, button) {
          cr.transport.successRequest(
            options.transportId,
            options.saveLabelActionName || "setlabel",
            savePacket,
            button,
            function(resp) {
              var mapObject = cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.formatLabelResponse(resp.fields),
                parentChanged = savePacket.parentId != label.parentLabelId(),
                oldParent = label.parentLabelId() ? this.getLabelById(label.parentLabelId()) : null,
                oldParentCollection = oldParent ? oldParent.children : this.labels,
                currentParentCollection = savePacket.parentId ? this.getLabelById(savePacket.parentId).children : this.labels;

              if (label.id()) {
                label.mapFromResponse(mapObject);
                label.hierarchyLevel(null);

                if (parentChanged) {
                  oldParentCollection.remove(label);
                  currentParentCollection.push(label);

                  cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.flattenLabelCollection(label.children()).forEach(function(l) {
                    l.hierarchyLevel(null);
                  });
                }
              } else {
                if (savePacket.isOrgLabel) {
                  mapObject.isPublic = true;
                }
                currentParentCollection.push(cr.viewModelFactory.commonViewModels.hierarchicalLabelVm.createFromResponse(mapObject));
              }

              if (options.applyLabelSaveToItemsLabels) {
                var l;
                this.items().forEach(function(i) {
                  l = i.getLabelById(label.id());
                  if (l) {
                    l.mapFromResponse(mapObject);
                  }
                });
              }

              typeof callback === "function" && callback();
              options.busConnection.sendMessage("labelSaved", mapObject);
            }.bind(this)
          );
        };

        pubs.deleteLabel = function(label, button, callback) {
          var request = { labelId: label.id() };
          cr.transport.successRequest(
            options.transportId,
            options.deleteLabelActionName || "removelabel",
            request,
            button,
            function(resp) {
              var parentCollection = +label.parentLabelId() ? this.getLabelById(label.parentLabelId()).children : this.labels;
              parentCollection.remove(label);

              if (options.applyLabelSaveToItemsLabels) {
                this.items().forEach(function(item) {
                  item.removeTheseLabels([label.id()]);
                });
              }

              typeof callback === "function" && callback();
              options.busConnection.sendMessage("labelDeleted", { labelId: label.id() });
            }.bind(this)
          );
        };
      }
    };
  }
  nestedLabelsPlugin.loadLabels = loadLabels;
  nestedLabelsPlugin.flattenLabels = flattenLabels;
  nestedLabelsPlugin.flattenAndSortLabels = flattenAndSortLabels;
  return nestedLabelsPlugin;
});
