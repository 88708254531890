define("framework/koMapper/plugins/selectableList", [], function() {
  var requireId = "vm-plugin-selectableList";

  return function(localConfig) {
    var alias = localConfig.alias;

    return {
      initialize: function(initializeOptions) {
        if (alias) this[alias] = this.items;

        this.loaded = ko.observable(false);
        this.loading = ko.observable(false);
        this.fresh = ko.observable(true);
        this.hasMore = ko.observable(false);

        // paged items could be 50 but total items can be much more. Upto 1000
        this.totalCount = ko.observable();

        this.selectedMode = ko.observable("");
        this.clearSelectedMode = function() {
          this.selectedMode("");
        }.bind(this);

        this.loadStart = function() {
          this.loading(true);
        };

        // running total of how many items loaded thus far and if we anticipate that there are more
        this.displayTotal = ko.computed(function() {
          return this.items().length + (this.hasMore() ? "+" : "");
        }, this);

        this.finishLoad = function(items, append, pageSize) {
          // Allow for a call saying "we're done, but didn't get anything"
          if (items) {
            if (append) {
              this.items.push.apply(
                this.items,
                items.map(function(item) {
                  return localConfig.vm.createFromResponse(item);
                })
              );
            } else {
              this.mapFromResponse({ items: items });
            }

            // if the returned list has < items than page size we definitely have no more results. Otherwise we likely do; false positives are possible
            if (typeof pageSize !== "undefined") {
              this.hasMore(items.length === pageSize);
            }
          }
          this.loading(false);
          this.fresh(false);
        };

        this.getItemById = function(id) {
          return Linq.From(this.items()).SingleOrDefault(null, function(item) {
            return item.id() == id;
          });
        };

        this.setupCurrentItem = function() {
          this.currentItem = ko.observable(new localConfig.vm());

          this.previousCurrentItem = null;

          this.currentItem.subscribe(
            function(previousCurrentItemValue) {
              this.previousCurrentItem = previousCurrentItemValue;
            },
            this,
            "beforeChange"
          );

          this.currentItem.subscribe(function(currentItem) {
            this.items().forEach(function(item) {
              item.active(false);
            });
            currentItem.active(true);
            this.loadedItemId(ko.unwrap(this.currentItem().id));
          }, this);
        };

        //some list viewmodels will need to do "stuff" to its item viewmodel before it can be created—often times put stuff on its prototype
        //this simple flag allows for that.
        if (!localConfig.deferCurrentItem) {
          this.setupCurrentItem();
        }

        this.loadedItemId = ko.observable("");

        this.items.subscribe(selectLoadedItem.bind(this));
        this.loadedItemId.subscribe(selectLoadedItem.bind(this));

        function selectLoadedItem() {
          var itemToSelect = this.items().filter(
            function(i) {
              return ko.unwrap(i.id) == this.loadedItemId();
            }.bind(this)
          )[0];

          if (!itemToSelect) {
            this.items().forEach(function(item) {
              item.active(false);
            });
          } else if (itemToSelect !== this.currentItem()) {
            this.currentItem(itemToSelect);
          }
        }

        this.multiSelectToggle = item => item.selected(!item.selected());
        this.multiSelectOne = item => item.selected(true);
        this.multiUnSelectOne = item => item.selected(false);

        this.selectOne = function(item, e) {
          // check if the user is currently holding down the ctrl key which would indicate they're wanting to select multiple items
          if (e.ctrlKey) {
            item.selected(!item.selected());
          } else {
            this.clearSelectedMode();

            //re-selecting current item does nothing
            if (this.currentItem() != item) {
              this.currentItem(item);
            }

            cr.messageBus.sendMessage(requireId, localConfig.selectedItemMessageName, { item: item });
          }
        }.bind(this);

        this.selectedItems = ko.computed(function() {
          return this.items().filter(function(i) {
            return i.selected();
          });
        }, this);
        this.selectedCount = ko.computed(function() {
          return this.selectedItems().length;
        }, this);

        this.toggleAllSelected = function() {
          this.allSelected(!this.allSelected());
          if (this.__selectableListBusConnection) {
            this.__selectableListBusConnection.sendMessage("manual-selection-change", {});
          }
        }.bind(this);

        this.cancelAllSelected = function() {
          this.allSelected(false);
          if (this.__selectableListBusConnection) {
            this.__selectableListBusConnection.sendMessage("manual-selection-change", {});
          }
        }.bind(this);

        this.allSelected = ko.computed({
          read: function() {
            return this.items().length && this.selectedCount() == this.items().length;
          },
          write: function(val) {
            return this.items().forEach(function(i) {
              i.selected(val);
            });
          },
          owner: this
        });

        //if the user unselects one or many.
        this.allSelected.subscribe(
          function(val) {
            if (!val) {
              this.totalSelected(false);
            }
          }.bind(this)
        );

        this.allIds = ko.observable();
        this.totalSelected = ko.observable();

        // when all are selected AND there is a total num AND total is larger then selected
        this.showTotalCount = ko.computed(
          function() {
            return this.allSelected() && this.totalCount() && this.totalCount() > this.selectedCount();
          }.bind(this)
        );

        // unselect "1000" items and only select items that are visible on the page
        this.selectListItmesOnly = function() {
          this.allIds(null);
          this.totalSelected(false);
          this.totalCount(this.totalCount() == 1000 ? 1001 : this.totalCount());
        }.bind(this);
      },
      postInitialize: function() {
        if (localConfig.deferCurrentItem) {
          this.setupCurrentItem();
        }
        if (typeof localConfig.busConnection === "function") {
          this.__selectableListBusConnection = localConfig.busConnection.call(this);
        } else if (typeof localConfig.busConnection === "object") {
          this.__selectableListBusConnection = localConfig.busConnection;
        }
      },
      define: function(config, pubs, F) {
        config.mappedArrays.push({ name: "items", viewModelType: localConfig.vm });

        if (alias) {
          config.mappedArrays.push({ name: alias, viewModelType: localConfig.vm });
        }
      }
    };
  };
});
