define("framework/globalUtils/autocompleter", [], function() {
  var $ = jQuery;

  // Some auto-completer helpers

  // Make an auto-completer that uses separate name and label fields and automatically
  // handles the spinner.
  // Accepts an options object, most of which is passed through to the auto-completer.
  // This function handles these properties on the options object:
  // `valueField`     jQuery instance for the value field
  // `labelField`     jQuery instance for the label field
  // `source`         we inject a function to handle setting the spinner
  // `select`         if you don't provide one, or you provide one that doesn't
  //                  return `false`, we ensure the label and value fields are populated
  // `update`         if you don't provide a `select` or you provide one that doesn't
  //                  return `false`, this function will get called after we update the
  //                  label and value fields.
  // `minLength`      if none is specified, 2 is used
  // `template`       [string|CRTemplate|function] if given, this controls the rendering
  //                  of items in the list.
  //                  If it's a string or a CRTemplate:
  //                      The template is evaluated using the item as the source object.
  //                      This can be as simple as just the label you want shown, e.g.:
  //                      Example 1:
  //                          "#{name} (#{id})"
  //                      Example 2:
  //                          "<li data-id="#{id}"><a>#{name} (#{id})</a></li>"
  //                  If it's a function:
  //                      It's called for each item with a reference to the `ul` being
  //                      constructed and the item in the list. It returns either a string
  //                      or a jQuery instance. If it returns a string, it's processed
  //                      just like a template string (see above). If it returns a jQuery
  //                      instance, that instance is appended to the list.
  //                      Example 1 (returning a string):
  //                          template: function(ul, item) {
  //                              return item.name + " (" + item.id + ")";
  //                          }
  //                      Example 2 (returning a jQuery instance):
  //                          template: function(ul, item) {
  //                              return $("<li>")
  //                                  .attr("data-id", item.id)
  //                                  .append($("<a>").text(item.name + " (" + item.id + ")"));
  //                          }
  //                  The things that end up being in the autocompleter's list must be
  //                  `li` elements containing `a` elements containing the item to
  //                  display. You can do that yourself in your template string / function,
  //                  but it's fine if you don't, we'll check and wrap it for you as
  //                  necessary (within reason).
  //                  The `item` object (whether used in a template or via a function)
  //                  will be the entry in the `source` list (if using a server
  //                  auto-completer, this is after both pre- and post-processing).
  function createBasicAutoCompleter(options) {
    var origSourceCallback, origSelectCallback, labelField, valueField, template, lastLabel, lastValue;

    // Copy then massage the options
    options = $.extend({}, options);
    origSourceCallback = options.source;
    options.source = handleSource;
    origSelectCallback = options.select;
    options.select = handleSelect;
    labelField = options.labelField;
    delete options.labelField;
    valueField = options.valueField;
    delete options.valueField;
    if (typeof options.minLength === "undefined") {
      options.minLength = 2;
    }
    options.focus = handleFocus;
    template = options.template;
    delete options.template;

    lastLabel = labelField.val();

    if (options.clearOnChange === true) {
      options.change = function(e) {
        if (jQuery.trim(labelField.val()) === jQuery.trim(lastLabel)) return;

        labelField.val("");
        valueField.val("");
        if (typeof options.update === "function") {
          options.update(e, { item: { label: "", value: "" } });
        }
      };
    }

    // Hook it up
    lastLabel = labelField.val();
    lastValue = valueField.val();
    labelField.autocomplete(options).on("autocompleteopen", function() {
      $(".ui-autocomplete").css("z-index", 1500);
    });

    // Make sure we undo uncommitted updates to the label field
    labelField
      .focus(function() {
        lastLabel = labelField.val();
        lastValue = valueField.val();
      })
      .blur(function() {
        if (valueField.val() === lastValue && labelField.val() !== lastLabel) {
          // Value hasn't changed but label has, put the label back
          labelField.val(lastLabel);
        }
      });

    // If we have a template, hook up item rendering
    // (See "Customizing Individual Instances" at http://learn.jquery.com/jquery-ui/widget-factory/extending-widgets/)
    if (template) {
      if (typeof template === "function") {
        labelField.data("ui-autocomplete")._renderItem = renderItemUsingFunction;
      } else {
        if (!(template instanceof CRTemplate)) {
          template = new CRTemplate(String(template));
        }
        labelField.data("ui-autocomplete")._renderItem = renderItemUsingTemplate;
      }
    }

    function handleSource(request, callback) {
      // Show the spinner
      labelField.removeClass("autoCompleteField").addClass("autoCompleteFieldBusy");

      // Ask for the data
      origSourceCallback(request, function(list) {
        // Remove the spinner
        labelField.removeClass("autoCompleteFieldBusy").addClass("autoCompleteField");

        // Show the data
        callback(list);
      });
    }

    function handleFocus(event, ui) {
      labelField.val(ui.item.label);
      return false;
    }

    function handleSelect(event, ui) {
      try {
        if (typeof origSelectCallback === "function") {
          if (origSelectCallback(event, ui) === false) {
            // Our callback cancelled our op
            return false;
          }
        }

        lastLabel = ui.item.label;

        // Standard processing
        if (ui.item.value === "") {
          // No matches found -- leave the label alone so they can edit it, but
          // be sure we clear the value.
          valueField.val("");
        } else {
          labelField.val(ui.item.label).trigger("change");
          valueField.val(ui.item.value).trigger("change");
        }

        // Post-select callback
        if (typeof options.update === "function") {
          options.update(event, ui);
        }

        // Done
        return false;
      } finally {
        // Whatever is there after this is complete is what we should record;
        // both 'select' and 'update' handlers may change things.
        lastLabel = labelField.val();
        lastValue = valueField.val();
      }
    }

    function renderItemUsingString(ul, item, str) {
      if (!str.match(/^<li[ >].*<\/li>$/i)) {
        if (str.match(/^<a[ >].*<\/a>$/i)) {
          str = "<li>" + str + "</li>";
        } else {
          str = "<li><a>" + str + "</a></li>";
        }
      }
      return $(str).appendTo(ul);
    }

    function renderItemUsingTemplate(ul, item) {
      return renderItemUsingString(ul, item, template.evaluate(item).trim());
    }

    function renderItemUsingFunction(ul, item) {
      var entry, tag;

      entry = template(ul, item);
      if (!entry.jquery) {
        return renderItemUsingString(ul, item, String(entry));
      }
      if (entry.length !== 1) {
        throw "cr.autocompleter template function returned an invalid jQuery instance";
      }
      tag = entry[0].tagName.toLowerCase();
      if (tag !== "li") {
        if (tag === "a") {
          entry = $("<li>").append(entry);
        } else {
          entry = $("<li>").append($("<a>").append(entry));
        }
      }
      ul.append(entry);
      return entry;
    }
  }

  // Formats the given array of objects so that it has `value` and `label`
  // properties, for use by an autocompleter. Accepts the names of the
  // source properties. Allows for `list` to be blank (supplies blank list).
  function formatAutoCompleterData(valuePropName, labelPropName, list) {
    if (!list) {
      list = [];
    } else {
      if (valuePropName === "value") {
        valuePropName = undefined;
      }
      if (labelPropName === "label") {
        labelPropName = undefined;
      }
      if (valuePropName || labelPropName) {
        $.each(list, function(index, entry) {
          if (valuePropName) {
            entry.value = entry[valuePropName];
            delete entry[valuePropName];
          }
          if (labelPropName) {
            entry.label = entry[labelPropName];
            delete entry[labelPropName];
          }
        });
      }
    }
    return list;
  }

  // Hook up an autocompleter to a server call.
  // Accepts the same options as Bundles_makeAutoCompleter plus:
  // `moduleId`       the ID of the module
  // `call`           the call to send
  // `callData`       data to send with the call ({} if none given); this can be a function, in which case
  //                  it gets called immediately before we issue the call and returns the data to send.
  //                  It receives the options object and the current value of the "label" field as inputs.
  //                  Presumably, it would use the current value of the "label" field when filling in the
  //                  data.
  // `preprocess`     if given, this is a callback used to pre-process the raw response before we process it
  //                  signature: `function(options, response)`
  //                  ...where `options` is the options object passed into this function, and `response` is
  //                  the response from the server. If this function returns `false`, the autocompleter is
  //                  not updated.
  // `postprocess`    if given, this is a callback used to post-process the list we derived from the raw
  //                  response; signature: `function(options, list)`
  //                  ...where `options` is the options object passed into this function, and `list` is
  //                  the list of entries with `value` and `label` fields we're sending to the autocompleter.
  //                  If this function returns `false`, the autocompleter is not updated.
  // `searchPropName` name of the property in the `callData` to put the search value on (default = "search")
  // `listPropName`   name of the property on the response containing the list
  // `valuePropName`  name of the property on each entry in the response list that's the "value"
  // `labelPropName`  name of the property on each entry in the response list that's the "label"
  // `noneFoundText`  the text to use when there are no matching entries; default is the standard "none found" text
  // ... and *minus* `source`, as we provide that.
  function createAutoCompleter(options) {
    var acOptions,
      moduleId,
      call,
      callData,
      preprocess,
      postprocess,
      searchPropName,
      listPropName,
      valuePropName,
      labelPropName,
      labelField,
      noneFoundText;

    // CReate the auto-completer options by copying ours then culling our specific ones;
    // grab our specific ones at the same time.
    acOptions = $.extend({}, options);
    moduleId = acOptions.moduleId;
    delete acOptions.moduleId;
    call = acOptions.call;
    delete acOptions.call;
    callData = acOptions.callData || {};
    delete acOptions.callData;
    preprocess = typeof acOptions.preprocess === "function" && acOptions.preprocess; // false, or the function
    delete acOptions.preprocess;
    postprocess = typeof acOptions.postprocess === "function" && acOptions.postprocess;
    delete acOptions.postprocess;
    searchPropName = acOptions.searchPropName || "search";
    delete acOptions.searchPropName;
    listPropName = acOptions.listPropName;
    delete acOptions.listPropName;
    valuePropName = acOptions.valuePropName;
    delete acOptions.valuePropName;
    labelPropName = acOptions.labelPropName;
    delete acOptions.labelPropName;
    noneFoundText = acOptions.noneFoundText || AC_DEFAULT_NONE_FOUND_TEXT;
    delete acOptions.noneFoundText;

    // We grab this but leave it for Bundles_makeAutoCompleter
    labelField = acOptions.labelField;

    // Set our source callback
    acOptions.source = handleSource;

    // Do it
    createBasicAutoCompleter(acOptions);

    function handleSource(request, callback) {
      var d;
      if (typeof callData === "function") {
        d = callData(options, labelField.val());
      } else {
        d = callData;
        callData[searchPropName] = labelField.val();
      }
      cr.transport.transmitRequest(moduleId, call, d, function(response) {
        var list;

        // Preprocess
        if (preprocess) {
          if (preprocess(options, response) === false) {
            return;
          }
        }

        // Derive
        list = formatAutoCompleterData(valuePropName, labelPropName, response[listPropName]);

        // Postprocess
        if (postprocess) {
          if (postprocess(options, list) === false) {
            return;
          }
        }

        // Show default if none found
        if (list.length === 0) {
          list = [{ label: noneFoundText, value: "" }];
        }

        // Send
        callback(list);
      });
    }
  }
  return createAutoCompleter;
});
