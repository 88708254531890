// This file lets us define custom elements types for CR, typically for reusable widgets; it's a
// KO node pre-processor we use to convert our custom elements to standard elements before the
// browser renders them.
//
// The normal case is to define your widget in its own .js and .htm files, and then
// have it register any custom elements when/if the .js file is loaded via RequireJS, using
// cr.widgets.addElementHandler. (See widgets/hslist.js for an example.) Or for one-liner,
// dead-simple element handlers, you can just add the handler below.
//
// In either case, you add the handler by calling addElementHandler (either directly if doing it
// below, or via cr.widgets.addElementHandler from outside this file). It takes one argument,
// which is the handler object to add.
//
// Handler objects have two properties:
//
//      "type": The element type name (loosely, "tag"). The element type name *must* have at least
//      one dash in it, in keeping with nascent specification for custom elements (below). Best
//      practice is to start the type name with cr- (e.g. cr-nifty) so it's clear when looking at
//      the raw HTML that this is a CR-specific element.
//
//      "process": The function that processes the element. When an element is encountered, we
//      look up the handler by the element type and call the process function with one argument,
//      the element wrapped in a jQuery object. The process function must return the replacment
//      for the element, either as a DOM element, an HTML string, or as a jQuery object.
//
// Custom element specs (we're not fully using custom elements -- yet!)
//      http://www.w3.org/TR/custom-elements/
//      http://w3c.github.io/webcomponents/spec/custom/
define("framework/globalUtils/koNodePreprocessor", [], function() {
  // The handlers, keyed by type
  var elementHandlers = {};

  // Add a handler
  function addElementHandler(handler) {
    var type;

    type = handler.type;
    if (!handler.type || handler.type.indexOf("-") === -1) {
      throw "Invalid custom element handler: `type` is missing, blank, or doesn't contain a dash";
    }
    if (typeof handler.process !== "function") {
      throw "Invalid custom element handler: `process` must be a function";
    }
    type = type.toLowerCase();
    if (elementHandlers[type]) {
      if (typeof console.error === "function") {
        console.error("Warning: A previous handler for `" + type + "` is already registered.  Ignoring this one.");
      }
      return;
    }
    elementHandlers[type] = handler;
  }

  // Map the attributes from the given source element to the given
  // destination object; both are assumed to be jQuery instances containing
  // one topmost element.
  // All attributes are copied/merged other than 'options' and any listed
  // on the 'skip' array.
  // Arguments:
  //      $src        The source object
  //      $dest       The destination object
  //      skip        An array of attributes to skip
  //                  By default
  // Returns:
  //      dest
  function mapAttributes($src, $dest, skip) {
    skip = skip || [];
    if (!($dest instanceof $)) $dest = $($dest);

    var attr,
      allAttributes = $src[0].attributes,
      attrName,
      specifiedDataBindings,
      originalDataBindings;

    for (var i = 0; i < allAttributes.length; i++) {
      attr = allAttributes.item(i);
      attrName = attr.nodeName;

      if (attrName === "options" || skip.indexOf(attrName) > -1) continue;

      if (attrName === "class") {
        $dest.addClass(attr.nodeValue);
      } else if (attrName === "data-bind") {
        specifiedDataBindings = attr.nodeValue;
      } else if (attrName === "style") {
        attr.nodeValue.split(";").forEach(function(stylePair) {
          var values = stylePair.split(":"),
            key = $.trim(values[0]),
            value = $.trim(values[1]);

          $dest.css(key, value);
        });
      } else {
        $dest.attr(attrName, attr.nodeValue);
      }
    }

    if (specifiedDataBindings) {
      originalDataBindings = $dest.attr("data-bind");
      $dest.attr("data-bind", (originalDataBindings ? originalDataBindings + ", " : "") + specifiedDataBindings);
    }

    return $dest;
  }

  // Register our preprocessor, see http://knockoutjs.com/documentation/binding-preprocessing.html#preprocessing_dom_nodes
  ko.bindingProvider.instance.preprocessNode = function(node) {
    var type, handler, $node, replacement;

    if (node.nodeType !== 1) {
      return;
    }

    type = $.trim(node.tagName.toLowerCase());
    handler = elementHandlers[type];
    if (handler) {
      $node = $(node);
      replacement = handler.process($node);
      if (typeof replacement === "string") {
        // String: Parse and get true array of contents
        replacement = $("<div></div>")
          .append(replacement)
          .contents()
          .get();
      } else if (replacement.jquery) {
        // jQuery object: Get true array of contents
        replacement = replacement.get();
      } else if (!Array.isArray(replacement)) {
        // Presumably a DOM element
        replacement = [replacement];
      }

      // Update; the KO docs are quite clear we need to be sure to insert new
      // nodes in front of the node.
      $node.before(replacement);
      $node.remove();
      return replacement;
    }
  };

  var validValidatingDiv = '<div data-bind="visible: #{observable}.isValid" class="fa fa-check"></div>',
    inValidValidatingDiv = '<div data-bind="visible: !#{observable}.isValid()" class="fa fa-exclamation"></div>';
  addElementHandler({
    type: "cr-validating",
    process: function($node) {
      $node.attr(
        "options",
        $node.attr("options").replace(/basedOn:\W*([A-Za-z0-9_$]+)/, function(a, b, c, d) {
          return "basedOn: '" + b + "'";
        })
      );

      var $dest = mapAttributes($node, $("<div />")),
        options = eval("({" + $node.attr("options") + "})"),
        validDiv = $(validValidatingDiv.replace(/#{observable}/gi, options.basedOn)),
        inValidDiv = $(inValidValidatingDiv.replace(/#{observable}/gi, options.basedOn));

      if (options.validToolTip) {
        validDiv.attr("title", options.validToolTip);
      }
      if (options.inValidToolTip) {
        inValidDiv.attr("title", options.inValidToolTip);
      }

      $dest.append(validDiv).append(inValidDiv);
      return $dest;
    }
  });

  const loading = `
        <div #{db}>
            <div class="overlay-loading">
                <div class="flex align-items-center justify-content-center">
                    <div class="width-120 text-center">
                        <div class="bubbles">
                            <span></span>
                            <span id="bubble2"></span>
                            <span id="bubble3"></span>
                        </div>
                        <div class="txt-muted-more margin-md-top txt-lg"><b>Loading</b></div>
                    </div>
                </div>
            </div>
        </div>
    `;

  addElementHandler({
    type: "cr-loading",
    process: function($node) {
      let showCondition = $node.attr("show");
      return loading.replace("#{db}", showCondition ? `data-bind="if: ${showCondition}"` : "");
    }
  });

  //***********************************************************************
  //***********************************************************************
  //***********************************************************************

  addElementHandler({
    type: "cr-modal",
    process: function($node) {
      var $dest = mapAttributes($node, modal, ["data-header", "data-footer-close"]),
        headerText = $node.data("header"),
        headerContent = $node.find("modal-header"),
        footerButtons = $node.find("footer-buttons"),
        noFooter = $node.is("[no-footer]"),
        footerContent = $node.find("footer-content"),
        footerCloseText = $node.data("footerClose"),
        bodyContent = $node.find("modal-body");

      if (headerText) $dest.find(".modal-header h4").text(headerText);
      else if (headerContent.length) $dest.find(".modal-header").append(headerContent.contents());

      $dest.find(".modal-body").append(bodyContent);

      if (footerCloseText) $dest.find(".modal-footer button").text(footerCloseText);

      if (noFooter) $dest.find(".modal-footer").remove();
      else if (footerButtons.length) $dest.find(".modal-footer").prepend(footerButtons.contents());
      else if (footerContent.length)
        $dest
          .find(".modal-footer")
          .empty()
          .append(footerContent.contents());

      return $dest;
    }
  });

  var modal =
    '<div class="modal">' +
    '<div class="modal-dialog">' +
    '<div class="modal-content">' +
    '<div class="modal-header clearfix">' +
    '<button type="button" class="close" data-dismiss="modal">&times;</button>' +
    '<h2 class="title modal-title"></h2>' +
    "</div>" +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer">' +
    '<button class="btn no-button" data-dismiss="modal">Close</button>' +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>";

  addElementHandler({
    type: "cr-render",
    process: function($node) {
      var html = $node.attr("html"),
        loadWith = $node.attr("load-with"),
        path = $node.attr("html") + ".htm",
        afterRender = $node.attr("afterRender"),
        afterRenderText = afterRender ? ", afterRender: " + afterRender : "";
      return loadWith
        ? ["<!-- ko template: { loadWith: " + loadWith + afterRenderText + " } -->", "<!-- /ko -->"].join("")
        : ['<!-- ko template: { name: "' + path + '"' + afterRenderText + " } -->", "<!-- /ko -->"].join("");
    }
  });

  // Expose our functions
  return {
    addElementHandler: addElementHandler,
    mapAttributes: mapAttributes,
    elementHandlers: elementHandlers
  };
});
