define("framework/globalUtils/hashUtil", [], function() {
  var $ = jQuery;

  var BeHashObjectPrototype = CRHashObject.prototype;
  BeHashObjectPrototype.addValue = addValue;
  BeHashObjectPrototype.setValue = setValue;
  BeHashObjectPrototype.getValue = getValue;
  BeHashObjectPrototype.getParameters = getParameters;
  BeHashObjectPrototype.removeValue = removeValue;

  function CRHashObject(module, submodule, parameters) {
    this.module = module || "";
    this.submodule = submodule || "";
    this.parameters = parameters || [];
  }

  function addValue(key, newValue) {
    if (this[key] === undefined) {
      this.setValue(key, newValue);
    } else {
      var parameterValueToConvert = findParameterWithKey.call(this, key);
      if (!$.isArray(parameterValueToConvert.value)) {
        parameterValueToConvert.value = [parameterValueToConvert.value];
        this[key] = [this[key]];
      }
      parameterValueToConvert.value.push(newValue);
      this[key].push(newValue);
    }
  }

  function setValue(key, value) {
    var currentParameter = findParameterWithKey.call(this, key);
    if (currentParameter === undefined) {
      currentParameter = { name: key, value: value };
      this.parameters.push(currentParameter);
    } else {
      currentParameter.value = value;
    }

    this[key] = value;
  }

  function removeValue(key) {
    for (var i = 0, max = this.parameters.length; i < max; i++) {
      if (this.parameters[i].name === key) {
        this.parameters.splice(i, 1);
        return;
      }
    }
  }

  function getValue(key) {
    var parameter = findParameterWithKey.call(this, key);
    return parameter && parameter.value;
  }

  function findParameterWithKey(key) {
    for (var i = 0, max = this.parameters.length; i < max; i++) {
      if (this.parameters[i].name === key) {
        return this.parameters[i];
      }
    }
  }

  function getParameters() {
    var result = {};
    for (var i = 0, max = this.parameters.length; i < max; i++) {
      result[this.parameters[i].name] = this.parameters[i].value;
    }
    return result;
  }

  var BeHashManagerPrototype = CRHashManager.prototype;

  BeHashManagerPrototype.parseHashTag = parseHashTag;
  BeHashManagerPrototype.createHashTag = createHashTag;
  BeHashManagerPrototype.replaceHash = replaceHash;
  BeHashManagerPrototype.applyChange = applyChange;
  BeHashManagerPrototype.overwriteChange = overwriteChange;
  BeHashManagerPrototype.applyTheseHashChanges = applyTheseHashChanges;
  BeHashManagerPrototype.overwriteTheseHashChanges = overwriteTheseHashChanges;
  BeHashManagerPrototype.getCurrentHashValueOf = getCurrentHashValueOf;
  BeHashManagerPrototype.removeFromHash = removeFromHash;
  BeHashManagerPrototype.overwriteRemoveFromHash = overwriteRemoveFromHash;
  BeHashManagerPrototype.registerHashUpdate = registerHashUpdate;
  BeHashManagerPrototype.registerHashDelegatedUpdate = registerHashDelegatedUpdate;

  BeHashManagerPrototype.getCurrentHashInfo = function() {
    return this.parseHashTag(window.location.hash);
  };

  BeHashManagerPrototype.getCurrentHashParameters = function() {
    return this.parseHashTag(window.location.hash).getParameters();
  };

  function CRHashManager(defaultSubmodule) {
    this.defaultSubmodule = defaultSubmodule;
  }

  function parseHashTag(hash) {
    if (hash.indexOf("#") > -1) {
      hash = hash.split("#")[1];
    }

    if (hash.charAt(hash.length - 1) === "/") {
      hash = hash.substr(0, hash.length - 1);
    }

    var modSubmodSection = hash.indexOf("?") > -1 ? hash.split("?")[0] : hash;
    var queryStringSection = hash.indexOf("?") > -1 ? hash.split("?")[1] : null;
    var modSections = modSubmodSection.split("/");

    var result = new CRHashObject(modSections[0], modSections[1]);

    if (queryStringSection) {
      var pairs = queryStringSection.split("&");
      for (var i = 0, max = pairs.length; i < max; i++) {
        var keyValuePair = pairs[i].split("=");
        var key = keyValuePair[0];
        var value = keyValuePair.length > 1 ? decodeURIComponent(keyValuePair[1]) : undefined;

        result.addValue(key, value);
      }
    }

    return result;
  }

  function createHashTag(hashObject) {
    var result = "";

    if (hashObject.submodule && !hashObject.module) {
      throw "INVALID module must be present if submodule is specified";
    }

    if (hashObject.module) {
      result += hashObject.module;
    }
    if (hashObject.submodule || this.defaultSubmodule) {
      result += "/" + (hashObject.submodule || this.defaultSubmodule);
    }

    result += "/";

    var allPairs = [];
    if (hashObject.parameters) {
      for (var i = 0, max = hashObject.parameters.length; i < max; i++) {
        if ($.isArray(hashObject.parameters[i].value)) {
          $.each(hashObject.parameters[i].value, function(unused, val) {
            allPairs.push(hashObject.parameters[i].name + "=" + encodeURIComponent(val));
          });
        } else {
          if (hashObject.parameters[i].value === "" || hashObject.parameters[i].value == null) {
            allPairs.push(hashObject.parameters[i].name);
          } else {
            allPairs.push(hashObject.parameters[i].name + "=" + encodeURIComponent(hashObject.parameters[i].value));
          }
        }
      }
    }

    if (allPairs.length) {
      result += "?" + allPairs.join("&");
    }

    return result;
  }

  function replaceHash(hashObj) {
    var currentUrl = window.location.toString(),
      base = currentUrl.split("#")[0],
      newHash = this.createHashTag(hashObj);

    window.location.replace(base + "#" + newHash);
  }

  function applyChange(name, value) {
    var hashInfo = this.parseHashTag(window.location.hash);
    setHashValueForHelper(hashInfo, name, value);
    setHash.call(this, hashInfo);
  }

  function applyTheseHashChanges(values) {
    var hashInfo = this.parseHashTag(window.location.hash);

    values.forEach(function(packet) {
      setHashValueForHelper(hashInfo, packet.key, packet.value);
    });

    setHash.call(this, hashInfo);
  }

  function overwriteChange(name, value) {
    overwriteTheseHashChanges.call(this, [{ key: name, value: value }]);
  }

  function overwriteTheseHashChanges(values) {
    var hashInfo = this.parseHashTag(window.location.hash);

    values.forEach(function(packet) {
      setHashValueForHelper(hashInfo, packet.key, packet.value);
    });

    var newHash = this.createHashTag(hashInfo);

    location.replace(location.href.replace(/#.*/, "#" + newHash));
  }

  function setHash(hashInfo) {
    var initialHash = window.location.hash.replace(/^#/, "").replace(/\/$/, "");

    var newHash = this.createHashTag(hashInfo);
    if (newHash.replace(/^#/, "").replace(/\/$/, "") == initialHash) {
      cr.messageBus.sendMessage("gu-hashUtil", "hash-change-swallowed", {});
    } else {
      window.location.hash = newHash;
    }
  }

  function getCurrentHashValueOf(name) {
    var hashObject = this.parseHashTag(window.location.hash);
    return hashObject.getValue(name);
  }

  function removeFromHash() {
    var hashInfo = this.parseHashTag(window.location.hash);
    $.each(arguments, function(i, val) {
      hashInfo.removeValue(val);
    });

    setHash.call(this, hashInfo);
  }

  function overwriteRemoveFromHash() {
    this.overwriteTheseHashChanges(
      [].map.call(arguments, function(arg) {
        return { key: arg };
      })
    );
  }

  function registerHashUpdate(selector, value, e) {
    var self = this;

    $(selector).bind(e || "click", function(evt) {
      hashModifyingHandler.call(this, evt, self, value, e || "click");
    });
  }

  function registerHashDelegatedUpdate(container, selector, value, e) {
    var self = this;

    $(container).on(e || "click", selector, function(evt) {
      hashModifyingHandler.call(this, evt, self, value, e || "click");
    });
  }

  function hashModifyingHandler(evt, self, value, eventName) {
    if (eventName == "click") evt.preventDefault();
    var initialHash = window.location.hash;
    var hashInfo = self.parseHashTag(window.location.hash);

    if (typeof value === "function") {
      var obj = value.call(this, evt);
      if (!obj) return;

      if (!$.isArray(obj)) {
        obj = [obj];
      }

      $.each(obj, function(i, item) {
        setHashValueForHelper(hashInfo, item.key, item.value);
      });
    } else if ($.isArray(value)) {
      for (var i = 0, max = value.length; i < max; i++) {
        setHashValueForHelper(hashInfo, value[i], $(this).data(value[i]));
      }
    } else {
      setHashValueForHelper(hashInfo, value, $(this).data(value));
    }
    window.location.hash = self.createHashTag(hashInfo);
    if (window.location.hash == initialHash) {
      cr.messageBus.sendMessage("gu-hashUtil", "hash-change-swallowed", {});
    }
  }

  function setHashValueForHelper(hashInfo, key, val) {
    if (val == null) {
      hashInfo.removeValue(key);
    } else {
      hashInfo.setValue(key, val);
    }
  }

  return {
    hashManager: CRHashManager,
    hashObject: CRHashObject
  };
});
