define("framework/koMapper/plugins/sideMenu", [], function() {
  /*
----------------------------------------------------------------------------------------------------------------------------
sideMenu vm plugin

 adds in methods to work with a bootstrap side menu / bar, which is controlled by adding a pml-open class to body, and requires
 a no-overflow-x class to already be present on body.  Plugin will add in no-overflow-x for you, and will rely on router
 to make sure it's removed when appropriate

 adds in methods to add, close and toggle the menu, as well as methods to "pin" it, that is, persist this state to
 subsequent loads of the method via local storage
 ----------------------------------------------------------------------------------------------------------------------------
*/

  return function(localConfig) {
    return {
      initialize: function() {
        var storageKeyName = "pin_sidemenu",
          storageKeyNameRight = "pin_sidemenu_right",
          storage = new cr.util.ModuleStorage(localConfig.module, localConfig.submodule),
          currentVal = storage.getItem(storageKeyName),
          currentValRight = storage.getItem(storageKeyNameRight);

        this.menuIsPinned = ko.observable(currentVal == 1 || currentVal == null ? true : false);

        // right sidebar is not not open if never set
        this.menuIsPinnedRight = ko.observable(currentValRight == 1 ? true : false);

        this.closeMenu = function() {
          $("body").removeClass("pml-open");
          this.menuIsOpen(false);

          storage.setItem(storageKeyName, 0);
          cr.messageBus.sendMessage("global-side-menu", "menu-changed", { open: false });
        };
        this.closeMenuRight = function() {
          $("body").removeClass("pml-open-right");
          this.menuIsOpenRight(false);

          storage.setItem(storageKeyNameRight, 0);
          cr.messageBus.sendMessage("global-side-menu-right", "menu-changed", { open: false });
        };

        this.openMenu = function() {
          $("body").addClass("pml-open");
          this.menuIsOpen(true);

          storage.setItem(storageKeyName, 1);
          cr.messageBus.sendMessage("global-side-menu", "menu-changed", { open: true });
        };
        this.openMenuRight = function() {
          $("body").addClass("pml-open-right");
          this.menuIsOpenRight(true);

          storage.setItem(storageKeyNameRight, 1);
          cr.messageBus.sendMessage("global-side-menu-right", "menu-changed", { open: true });
        };

        this.syncSideMenuAfterActivation = function() {
          if (this.menuIsOpen()) {
            $("body").addClass("pml-open");
          }
          if (this.menuIsOpenRight()) {
            $("body").addClass("pml-open-right");
          }
        };

        this.menuIsOpen = ko.observable($("body").hasClass("pml-open"));
        this.menuIsOpenRight = ko.observable($("body").hasClass("pml-open-right"));

        // allows the user to pin or un-pin the menu on the sidebar so that subsequent visits will retain this value
        this.pinMenu = function() {
          this.menuIsPinned(true);
          storage.setItem(storageKeyName, 1);
        };
        this.pinMenuRight = function() {
          this.menuIsPinnedRight(true);
          storage.setItem(storageKeyNameRight, 1);
        };

        this.unPinMenu = function() {
          this.menuIsPinned(false);
          storage.setItem(storageKeyName, 0);

          if (this.menuIsOpen()) {
            this.closeMenu();
          }
        };
        this.unPinMenuRight = function() {
          this.menuIsPinnedRight(false);
          storage.setItem(storageKeyNameRight, 0);

          if (this.menuIsOpenRight()) {
            this.closeMenuRight();
          }
        };

        this.togglePinMenu = function() {
          if (this.menuIsPinned()) this.unPinMenu();
          else this.pinMenu();
        };
        this.togglePinMenuRight = function() {
          if (this.menuIsPinnedRight()) this.unPinMenuRight();
          else this.pinMenuRight();
        };

        if (this.menuIsPinned()) {
          this.openMenu();
        }

        if (this.menuIsPinnedRight()) {
          this.openMenuRight();
        }

        // at a certain minimum width, which matches the css class at which the container no longer "pads" the sidebar
        // we force the sidebar to close if its currently open
        //
        // at the opposite end, if the user had set the menu to be "pinned" and we reach a certain minimum width
        // we'll automatically open it for them
        enquire.register("(max-width: 766px)", {
          match: function() {
            this.closeMenu();
            this.closeMenuRight();
          }.bind(this),
          unmatch: function() {
            if (this.menuIsPinned()) {
              this.openMenu();
            }
            if (this.menuIsPinnedRight()) {
              this.openMenuRight();
            }
          }.bind(this)
        });
      }
    };
  };
});
