define("framework/globalUtils/settings", ["framework/globalUtils/transport"], function(transport) {
  var $ = jQuery;

  return {
    set: function(module, action, settings) {
      var dfd = new $.Deferred(),
        request;

      if (typeof settings === "object") {
        settings = JSON.stringify(settings);
      }

      request = {
        module: module,
        action: action,
        settings: settings
      };

      cr.transport.transmitRequest("shared", "savesettings", request, function(response) {
        if (response.success) return dfd.resolve(response);
        return dfd.reject(response);
      });

      return dfd.promise();
    },

    get: function(module, action) {
      var dfd = new $.Deferred(),
        request = {
          module: module,
          action: action
        };

      cr.transport.transmitRequest("shared", "loadsettings", request, function(response) {
        if (response.success) {
          if (response.settings) response.settings = JSON.parse(response.settings);
          return dfd.resolve(response);
        }
        return dfd.reject(response);
      });

      return dfd.promise();
    }
  };
});
