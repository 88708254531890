define("framework/koMapper/plugins/selectableItem", [], function() {
  return function(localConfig) {
    return {
      define: function(config, pubs, F) {
        config.basicObservables.push("selected", "active", "hovered");

        pubs.mouseOver = function() {
          this.hovered(true);
        };

        pubs.mouseOut = function() {
          this.hovered(false);
        };
      }
    };
  };
});
